import React, { useState, useEffect } from 'react'
// ** MUI Imports
import Box from '@mui/material/Box'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useAlert } from 'react-alert'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import FormControlLabel from '@mui/material/FormControlLabel'
import SuiInput from 'components/SuiInput'

import Button from '@mui/material/Button'
import axios from 'axios'
import { Grid, Input, Typography } from '@mui/material'
import ReactSelect from 'react-select'
import Checkbox from '@mui/material/Checkbox'
import { Controller, useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const ProviderSchedulerItem = (props) => {
  // const _providerId = props.providerId
  const [isLoading, setisLoading] = useState(false)
  const [submitting, setsubmitting] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [defaultFacility, setdefaultFacility] = useState('')
  const [providerid, setproviderid] = useState(true)

  const [disableById, setDisableById] = useState(true)
  
  const alert = useAlert()

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }

  const validationSchema = Yup.object({
    startTime: Yup.string().required('From Time is required').nullable(),
    endTime: Yup.string().required('To Time is required').nullable(),
    defaultFacility: Yup.boolean()
      .oneOf([true], 'Please select this checkbox to continue')
      .required(''),
  })
  const fields = [
    'id',
    'dateFrom',
    'dateTo',
    'providerId',
    'dayOFWeek',
    'notes',
    'timeIntervals',
    'startTime',
    'endTime',
    'oneday',
    'inActive',
    'createdDate',
    'updatedDate',
    'createdBy',
    'clientCreatedDate',
    'clientUpdatedDate',
    'defaultFacility',
  ]
  const defaultValues = {
    id: 0,
    dateFrom: '',
    dateTo: '',
    providerId: '',
    dayOFWeek: '',
    notes: '',
    timeIntervals: '',
    startTime: '',
    endTime: '',
    oneday: '',
    inActive: '',
    createdDate: '',
    updatedDate: '',
    createdBy: '',
    clientCreatedDate: '',
    clientUpdatedDate: '',
    defaultFacility: '',
  }

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {

    if(props.providerId == '')
    {
      return;
    }
    setisLoading(true)
    setDisableById(false)
    const postData = {
      providerId: props.providerId != null ? props.providerId : '',
      dayOFWeek: props.dayOFWeek,
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/ProviderScheduler/GetProviderScheduler`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        fields.forEach((field) => {
          setValue(field, response.data[field])
        })
        setisLoading(false)

        // reset();  
props.getData(response.data.providerId)
        console.log('Respponse my jo Data Parha ha', response.data)
      })

      .catch((error) => {
        console.error(error)
        setisLoading(false)
      })
  }, [props.providerId])

  const onSubmit = (data) => {
    ;(data.dayOFWeek = props.dayOFWeek),
      (data.providerId = props.providerId != null ? props.providerId : ''),
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/ProviderScheduler/addProviderScheduler`,
          data,
          { headers }
        )
        .then((response) => {
          setsubmitting(false)
          setRefresh(true)
          console.log('API response: ', response.data)
          alert.success('Record saved successfully.', {
            type: 'success',
          })
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
  }

  return (
    <Box>
      <>
        <div>

       
          <Grid
            container
            columnSpacing={4}
            rowSpacing={4}
            alignContent="center"
            justifyContent="center"
            style={{
              paddingTop: '20px',
              paddingLeft: '100px',
              paddingRight: '100px',
            }}
          >
            {isLoading ? (
                <LinearProgress />
            ) : (
              <>
                <Grid item xs={1} style={{ fontWeight: 500 }}>
                  <SuiInput
                    name="defaultFacility "
                    size="small"
                    type="checkbox"
                     disabled = {disableById}
                    {...register('defaultFacility')}
                    style={{
                      width: '15px',
                      height: '20px',
                      marginTop: '3px',
                      marginLeft: '5px',
                      cursor: 'pointer',
                    }}
                    id="defaultFacility"
                  />
                  {/* <SuiInput
                  type="checkbox"
                  margin="dense"
                  {...register('defaultFacility')}
                /> */}
                </Grid>

                <Grid item xs={2} style={{ marginLeft: '-15px' }}>
                  <Typography
                    color={errors.defaultFacility ? 'error' : 'black'}
                    variant="caption"
                    fontSize="15px"
                  >
                    {props.dayOFWeek}
                  </Typography>

                  {errors.defaultFacility && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {errors.defaultFacility?.message}
                    </p>
                  )}
                </Grid>

                <Grid item xs={1}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    From
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ width: '300px', marginLeft: '0px' }}>
                  <Controller
                    name="startTime"
                    {...register('startTime')}
                    control={control}
                    disabled = {disableById}
                    render={({ field }) => (
                      <TextField
                        type="time"
                        size="small"
                        fullWidth
                        {...field}
                        // value={dateFrom}
                        onChange={(e) => {
                          setValue('startTime', e.target.value)
                          // setDateTo(e.target.value)
                        }}
                        error={errors.startTime}
                      />
                    )}
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>
                    {errors.startTime?.message}
                  </p>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="black"
                    variant="caption"
                    fontSize="15px"
                    marginLeft="30px"
                    marginRight="0px"
                  >
                    To
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ width: '300px', marginLeft: '0px' }}>
                  <Controller
                    name="endTime"
                    {...register('endTime')}
                    control={control}
                    disabled = {disableById}
                    render={({ field }) => (
                      <TextField
                        type="time"
                        size="small"
                        fullWidth
                        {...field}
                        // value={dateFrom}
                        onChange={(e) => {
                          setValue('endTime', e.target.value)
                          // setDateTo(e.target.value)
                        }}
                        error={errors.endTime}
                      />
                    )}
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>
                    {errors.endTime?.message}
                  </p>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting ? submitting : disableById}
                    variant="gradient"
                    style={{
                      marginTop: '0px',
                      marginBottom: '10px',
                      // float: 'right',
                      marginLeft: '30px',
                      width: '70px',
                      height: '30px',
                      backgroundColor: '#A574FD',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    <SaveOutlinedIcon
                      fontSize="medium"
                      style={{
                        color: 'white',
                        paddingRight: '5px',
                        paddingBottom: '2px',
                      }}
                    />
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </>
    </Box>
  )
}

export default ProviderSchedulerItem
