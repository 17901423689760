import Card from '@mui/material/Card'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Table from 'examples/Tables/Table'
import React, { useEffect, useState, } from 'react'
import { createBrowserHistory } from 'history'
import { DataGrid } from '@mui/x-data-grid'
import { Controller, useForm } from 'react-hook-form'
import { Grid, Snackbar, TextField, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import PHQ9GAD7Filters from './PHQ9GAD7Filters'
import { CircularProgress, } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Demographics from './Demographics'
import Modal from '@mui/material/Modal'
import Moment from 'moment'


const history = createBrowserHistory({ forceRefresh: true })
var providersRedux = []
var facilitiesRedux = []

function Phq9gad7(props) {

  const [refresh, setRefresh] = useState(false)
  const [pageSize, setPageSize] = React.useState(25);
  const [PHQ9rows, setPHQ9rows] = useState('')
  const [tabValue, settabValue] = React.useState(0)
  const [rows, setrows] = useState('')
  const [RowssMsg, setRowssMsg] = useState('')

  const [Gad7rows, setGad7rows] = useState('')
  const [RecordsGAD7Msg, setRecordsGAD7Msg] = useState('')
console.log(RecordsGAD7Msg,'RecordsGAD7Msg')
  const [submitting, setsubmitting] = useState(false)
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [dob, setdob] = React.useState('')
  const [cellPhone, setcellPhone] = React.useState('')
console.log(dob,'dobdob')
  const [emailAddress, setemailAddress] = React.useState('')

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    textTransform: 'none !important',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
    '& .SUBTOTAL': {
      backgroundColor: 'rgba(200, 150, 255, 0.49)',
      color: 'black !important',
      border: '1px solid black !important'
    },
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: (params) => params.id,
      hide: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      colSpan: ({ row }) => {
        if (row.id == "SUBTOTAL") {
          return 8;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        if (row.id == "SUBTOTAL") {
          return row.label;
        }
        return value;
      },
    },
    // {
    //   field: 'firstName',
    //   headerName: 'First Name',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    //   // renderCell: (cellValues) => (
    //   //   <SuiButton
    //   //     size="small"
    //   //     variant="text"
    //   //     color="dark"
    //   //     // onClick={(event) => {
    //   //     //   openUserRegistration(event, cellValues.row.id)
    //   //     // }}
    //   //     onClick={handleOpen1}
    //   //   >{`${cellValues.row.firstName}`}</SuiButton>
    //   // ),

    //   // renderCell: (cellValues) => (
    //   //   <SuiButton
    //   //   style={{width:'auto'}}
    //   //     onClick={(event) => {
    //   //       // console.log('Cell: ', cell)
    //   //       setfirstName(cellValues.row.firstName)
    //   //       setlastName(cellValues.row.lastName)
    //   //       setdob(cellValues.row.dob)
    //   //       setemailAddress(cellValues.row.emailAddress)
    //   //       setcellPhone(cellValues.row.cellPhone)
    //   //       handleOpen1()
    //   //     }}
    //   //   >
    //   //   {`${cellValues.row.firstName}`}
    //   //   </SuiButton>
    
    //   // ),
    // },
    // {
    //   field: 'lastName',
    //   headerName: 'Last Name',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.2,
    // },
    {
      field: 'fullName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Typography
          style={{ fontSize:"small" }}
          // onClick={(event) => {
          //   setfirstName(cellValues.row.firstName);
          //   setlastName(cellValues.row.lastName);
          //   setdob(cellValues.row.dob);
          //   setemailAddress(cellValues.row.emailAddress);
          //   setcellPhone(cellValues.row.cellPhone);
          //   handleOpen1();
          // }}
        >
          {`${cellValues.row.lastName} ${cellValues.row.firstName}`}
        </Typography>
      ),
    },
    
    // {
    //   field: 'totalPhq9',
    //   headerName: 'Last Score',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.2,
    // },

    {
      field: 'lastPhq9Date',
      headerName: 'Last Phq9 Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    // {
    //   field: 'miN_SCORE',
    //   headerName: 'Min Score',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    // },
    // {
    //   field: 'maX_SCORE',
    //   headerName: 'Max Score',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    // },
    {
      field: 'cellPhone',
      headerName: 'Phone Number',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'lastPhq9Score',
      headerName: 'Last Phq9 Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'patientId',
      headerName: 'Patient',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <SuiButton
        style={{width:'auto'}}
          onClick={(event) => {
            setfirstName(cellValues.row.firstName)
            setlastName(cellValues.row.lastName)
            setdob(cellValues.row.dob)
            console.log(cellValues.row.dob,'cellValues.row.dob')
            setemailAddress(cellValues.row.emailAddress)
            setcellPhone(cellValues.row.cellPhone)

            handleOpen1()
          }}
        >
          <AddOutlinedIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
            }}

          ></AddOutlinedIcon>
          Edit
          {/* {`${cellValues.row.patientId}`} */}
        </SuiButton>
        // <AddOutlinedIcon
        //   style={{
        //     marginRight: '5px',
        //     float: 'right',
        //     marginTop: '5px',
        //     color:'red',
        //   }}

        // ></AddOutlinedIcon>
      ),
    },
    {
      field: 'phQ9AttemptCount',
      headerName: 'Total Attempts',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      // sx={{ marginLeft: '5px'}},
    },

  ]

  const GAD7columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: (params) => params.id,
      hide: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      colSpan: ({ row }) => {
        if (row.id == "SUBTOTAL") {
          return 8;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        if (row.id == "SUBTOTAL") {
          return row.label;
        }
        return value;
      },
    },
    // {
    //   field: 'firstName',
    //   headerName: 'First Name',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.4,
    //   // renderCell: (cellValues) => (
    //   //   <SuiButton
    //   //     size="small"
    //   //     variant="text"
    //   //     color="dark"
    //   //     onClick={(event) => {
    //   //       handleOpen1(event, cellValues.row.id)
    //   //     }}
    //   //     // onClick={handleOpen1}
    //   //   >
    //   //     {`${cellValues.row.firstName}`}
    //   //     </SuiButton>
    //   // ),

    //   // renderCell: (cellValues) => (
    //   //   <SuiButton
    //   //     onClick={(event) => {
    //   //       // console.log('Cell: ', cell)
    //   //       setfirstName(cellValues.row.firstName)
    //   //       setlastName(cellValues.row.lastName)
    //   //       setdob(cellValues.row.dob)
    //   //       setemailAddress(cellValues.row.emailAddress)
    //   //       setcellPhone(cellValues.row.cellPhone)
    //   //       handleOpen1()
    //   //     }}
    //   //   >
    //   //   {`${cellValues.row.firstName}`}
    //   //   </SuiButton>
    
    //   // ),
    // },
    // {
    //   field: 'lastName',
    //   headerName: 'Last Name',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    // },
    {
      field: 'fullName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Typography
          style={{ fontSize:'small' }}
          // onClick={(event) => {
          //   setfirstName(cellValues.row.firstName);
          //   setlastName(cellValues.row.lastName);
          //   setdob(cellValues.row.dob);
          //   setemailAddress(cellValues.row.emailAddress);
          //   setcellPhone(cellValues.row.cellPhone);
          //   handleOpen1();
          // }}
        >
          {`${cellValues.row.lastName} ${cellValues.row.firstName}`}
        </Typography>
      ),
    },
    
    {
      field: 'lastGad7Score',
      headerName: 'Last Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'lastGad7Date',
      headerName: 'Last GAD7 Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'minTotalScore',
      headerName: 'Min Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'maxTotalScore',
      headerName: 'Max Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'patientId',
      headerName: 'Patient',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <SuiButton
          onClick={(event) => {
            // console.log('Cell: ', cell)
            setfirstName(cellValues.row.firstName)
            setlastName(cellValues.row.lastName)
            setdob(cellValues.row.dob)
            setemailAddress(cellValues.row.emailAddress)
            setcellPhone(cellValues.row.cellPhone)
            handleOpen1()
          }}
        >
          <AddOutlinedIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
            }}

          ></AddOutlinedIcon>
          Edit
          {/* {`${cellValues.row.patientId}`} */}
        </SuiButton>
        // <AddOutlinedIcon
        //   style={{
        //     marginRight: '5px',
        //     float: 'right',
        //     marginTop: '5px',
        //     color:'red',
        //   }}

        // ></AddOutlinedIcon>
      ),
    },
    {
      field: 'attemptCount',
      headerName: 'Total Attempts',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      // sx={{ marginLeft: '5px'}},
    },

  ]


  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto'
  }
  const handleChange = (event, newValue) => {
    settabValue(newValue)
    console.log(newValue, "Tab Value ")
  }

  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    // setpatientId(0)
    setOpen1(false)
  }
  // useEffect(() => {
  //   handleOpen1()
  // },[firstName,lastName,dob])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ facilitiesRedx }])

  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // console.log(providersRedux);

  }, [{ providersRedux }])

  const openUserRegistration = (event, id) => {
    console.log('Clicked UserEmail: ', id)
    history.push({
      pathname: `/Phq9gad7Registration`,
      state: { userId: id },
    })
  }


  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  })


  return (
    <>

      <PHQ9GAD7Filters
        getRecords={setrows}
        getRecordsMsg={setRowssMsg}
        getRecordsGAD7={setGad7rows}
        getRecordsGAD7Msg={setRecordsGAD7Msg}
        tabValue={tabValue}
      // submitting = {submitting}
      />
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleDetails}>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <CancelOutlinedIcon
                onClick={handleClose1}
                fontSize='medium'
                style={{ color: 'red', marginBottom: '10px', float: 'right' }}
              ></CancelOutlinedIcon>
            </Grid>
            <Demographics handleClose1={handleClose1} cellPhone={cellPhone} emailAddress={emailAddress} firstName={firstName} lastName={lastName} DOB={dob}></Demographics>
          </Grid> 
        </Box>
      </Modal>

      <Grid
        container
        spacing={1}
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '50px',
        }}
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab value={0} label="PHQ 9" />
              <Tab value={1} label="GAD 7" />
            </TabList>

            <TabPanel value={0}>

              <Grid xs={12} xl={12} spacing={1} alignContent="left" justifyContent="left">
              </Grid>


              <Grid item xs={12} >
                {/* {submitting ? ("Please Wait! Your Data is Being Loaded...") : ( */}
                <SuiBox mb={3}>
                  <div style={{ height: 400, width: '100%' }}>
                    {rows === null || rows.length === 0 ?  ("Please Apply Filter") : (
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        headerHeight={37}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={gridRowStyle}
                      />
                    )}
                  </div>
                </SuiBox>
                {/* )} */}

              </Grid>


            </TabPanel>

            <TabPanel value={1}>
              <Grid spacing={1} alignContent="left" justifyContent="left" style={{
                width: '100% !important',
                marginLeft: '0px !important'
              }}>
              </Grid>
              <Grid item xs={12} >
                <SuiBox mb={3}>
                  <div style={{ height: 400, width: '100%' }}>
                    {Gad7rows === null || Gad7rows.length === 0 ? ("Please Apply Filter") : (
                      <DataGrid
                        rows={Gad7rows}
                        columns={GAD7columns}
                        headerHeight={37}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={gridRowStyle}
                      />
                    )}
                  </div>
                </SuiBox>
              </Grid>
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    </>
  )
}

export default Phq9gad7
