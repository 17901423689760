import * as React from 'react'
import { Grid, CircularProgress, Box, }
  from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ClearIcon from 'assets/clear.png'
import { createBrowserHistory } from 'history'
import { getUserPractices } from 'redux/Actions/usersActions'
// import { getAllProviders } from 'redux/Actions/allProvidersActions'
import { getProvidersNames } from 'redux/Actions/providersActions'
import { getFacilities } from 'redux/Actions/facilitiesActions'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import SuiSelect from 'components/SuiSelect'
import AddIcon from 'assets/add.png'
import SearchIcon from 'assets/search.png'
import InviteIcon from 'assets/invite.png'
import EditIcon from 'assets/edit.png'
import { TextField, Typography, Modal } from '@mui/material'
import SuiInput from 'components/SuiInput'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import NativeSelect from '@mui/material/NativeSelect'
import InputMask from 'react-input-mask'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from '@mui/material/Button'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
import { useAlert } from 'react-alert'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import { CancelOutlined, SaveOutlined } from '@mui/icons-material'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import CallIcon from '@mui/icons-material/Call';
import CancelIcon from '@mui/icons-material/Cancel';

import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PatientRegistration from './PatientRegistration'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import { Backdrop } from '@mui/material';


var providersRedux = []
const userProviderId = `${localStorage.getItem('dtt_user_provider_id')}`



const history = createBrowserHistory({ forceRefresh: true })
const headers = {
  Authorization: `Bearer  ${localStorage.getItem('dtt_token')}`,
}
function PatientSearchNew() {


  const [readOnly, setReadOnly] = useState(false)
  const [userType, setUserType] = useState(`${localStorage.getItem('dtt_userType')}`)
  const [providerID, setProviderID] = useState('')
  const [patientName, setpatientName] = useState('')
  console.log(patientName,'providerName,,')
  const NO_ROWS_LABEL = 'No Record found'
  const GETTING_ROWS_LABEL = 'Please wait!! Your data is being loaded..'
  const [NoRowsLabel, setNoRowsLabel] = useState(false)
  const [minimized, setMinimized] = useState(false);

  const alert = useAlert()

  const [patientIdArr, setPatientIdArr] = useState([]);

  console.log("patientIdArr my kya ha", patientIdArr)
  const [OpenBulkModal, setOpenBulkModal] = useState(false)

  const handleOpenBulkModal = () => setOpenBulkModal(true);
  const handleCloseBulkModal = () => setOpenBulkModal(false);

  //Ring central work start

  const [openn, setOpenn] = useState("");
  const [link, setLink] = useState("");
  const [requestId, setRequestId] = useState("");
  const [doc, setDoc] = useState(
    'https://ringcentral.github.io/ringcentral-embeddable/app.html'
  )
  // const requestId = "";
  //Ringcentral work end 
  const [open1, setOpen1] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const handleClickOpen1 = () => {
    console.log('first,,')
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };


  // useEffect(() => {
  //   // POST request using axios inside useEffect React hook

  //   var allProvidersRedux = []
  //   // console.log('reduxxx', providersRedx)
  //   allProvidersRedx.forEach((field) => {
  //     allProvidersRedux.push({
  //       label: field.lastName + ', ' + field.firstName,
  //       value: field.id,
  //     })
  //   })

  //   setAllProviders(allProvidersRedux)
  //   //     if (dtt_userType != 'Admin')
  //   //     {
  //   //       setValue('providerId', providersRedux[0].value)
  //   //     }
  //   // console.log('provideredux',providersRedux[0].value)

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])
 
  const handleDelete1 = () => {
    const postData = {
      id: selectedDocumentId.row.id,
      inactive: true,
      firstName: selectedDocumentId.row.firstName,
      lastName: selectedDocumentId.row.lastName,
      // userId: cell.row.userId,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
        headers,
      })
      .then((response) => {
        setrefreshAssessment(!refreshAssessment);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    handleClose1();
  };

  const onRowsSelectionHandler = (ids) => {
    // console.log(ids);
    setPatientIdArr(ids);
    // const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    // console.log(selectedRowsData);
  };

 const openPatientRegistration = (providerId, id,firstName,lastName) => {
  history.push({
    pathname: `/patientsregistration`,
    state: {firstName:firstName,
      lastName:lastName,
      patientId: id,
      readOnly: userType !== 'Admin' && userProviderId !== providerId,
      selectedTabAppointment: 0, // Add the selectedTabAppointment value here
    },
  });
  window.location.reload(false);
};

  const openNewPatientRegistration = () => {

    history.push({
      pathname: `/patientsregistration`,
      state: { patientId: undefined, readOnly: false },
    })
  }

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  }

  const selectedTabAppointment = 3;

  const columns = [
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      width: 80,
      // flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 1,
      renderCell: (cellValues) => ( 
        <SuiButton
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            // setProviderName(cellValues.row.firstName);
            
            setProviderID(cellValues.row.providerId);
            // setProviderName(cellValues.row.lastName );
            setpatientName(cellValues.row.firstName + ' ' + cellValues.row.lastName);

            localStorage.setItem('firstName', cellValues.row.firstName);
            localStorage.setItem('lastNames',cellValues.row.lastName);
            openPatientRegistration(cellValues.row.providerId,cellValues.row.id,cellValues.row.firstName, cellValues.row.lastName);
          }}
        >
          {`${cellValues.row.firstName}`}
        </SuiButton>
      ),
    }
,    

    {
      field: 'lastName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last Name',
      // width: 205,
      flex: 1,
    },
    {
      field: 'dob',
      headerClassName: 'super-app-theme--header',
      headerName: 'DOB',
      type: 'date',
      // width: 160,
      flex: 1,
    },
    {
      field: 'program',
      headerClassName: 'super-app-theme--header',
      headerName: 'Program',
      sortable: true,
      // width: 160,
      flex: 1,
    },
    {
      field: "providerName",
      headerClassName: "super-app-theme--header",
      headerName: "Provider",
      width: 150,
    },
    {
      field: 'status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Status',
      sortable: true,
      // width: 100,
      flex: 1,
    },
    {
      field: 'invite',
      headerClassName: 'super-app-theme--header',
      headerName: 'Patient App',
      sortable: false,
      width: 80,
      flex: 1,
      renderCell: (cellValues) => {
        {
          return cellValues.row.patientLogin ? (
            <SuiButton
              size="small"
              variant="gradient"
              color="warning"
              disabled={readOnly == true ? true : false}
              onClick={(event) => {
                history.push({
                  pathname: `/invitePatient`,
                  state: {
                    patientId: cellValues.row.id,
                    patientLogin: cellValues.row.patientLogin,
                  },
                })
              }}
              style={{ width: '70px' }}
              startIcon={
                <img
                  src={EditIcon}
                  style={{ marginTop: '-3px', marginRight: '-6px' }}
                />
              }
            >
              Edit
            </SuiButton>
          ) : (
            <SuiButton
              size="small"
              variant="gradient"
              color="warning"
              disabled={readOnly == true ? true : false}
              onClick={(event) => {
                history.push({
                  pathname: `/invitePatient`,
                  state: { patientId: cellValues.row.id },
                })
              }}
              style={{ width: '70px' }}
              startIcon={
                <img
                  src={InviteIcon}
                  style={{ marginTop: '-3px', marginRight: '-6px' }}
                />
              }
            >
              Invite
            </SuiButton>

          )
        }
      },
    },
    {
      field: 'delete', headerClassName: 'super-app-theme--header',
      headerName: '',
      sortable: true,
      flex: 0.1,
      renderCell: (cell) => (
        <div>
      <DeleteIcon
        style={{color:'red',
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
        }}
        color="red"
        onClick={()=>{handleClickOpen1()

          setSelectedDocumentId(cell)
        }}
      />

      <Dialog open={open1}
      aria-labelledby="responsive-dialog-title"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {background:'#cef5f0',
                    boxShadow:'none',
                    borderRadius:'8px'
                    },
                 }}                

      onClose={handleClose1}>
    <div style={{borderRadius:'8px'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={handleDelete1} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={handleClose1} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
      </Dialog>
    </div>
      ),
    },
  ]
 const Fname=  localStorage.getItem('firstName')

  const onSubmit = (data) => {

    const postData = data;
    const updaterow = {
      patientId: patientIdArr,
      assignedTo: postData.assignedTo,
      dueDate: postData.dueDate,
      providerId: postData.providerId,
      intakeStatus: postData.intakeStatus,
      careManagementStatus: postData.careManagementStatus,
      estimatedMonthlyTime: postData.estimatedMonthlyTime,
      timeLogDelete: postData.timeLogDelete,
      status: postData.status,
      comments: postData.comments,
      careManager: postData.careManager,
      leadCareManager: postData.leadCareManager,
      careManagerSupervisor: postData.careManagerSupervisor,
      billingProviderId: postData.billingProviderId,
      carePlanStatus: postData.carePlanStatus,
      program: postData.program,
      smokingStatus: postData.smokingStatus,
    };
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/updateBulkPatient`,
        updaterow,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.id) {
          setSubmitting(false);
        } else {
          setSubmitting(false);
          setOpenBulkModal(false);
          setPatientIdArr([]);
          setrefreshAssessment(!refreshAssessment)
          alert.success("Record Updated successfully.", {
            type: "success",
            onClose: () => {
              // setrefreshAssessment(true)
            }
          });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      });
  };

  const dispatch = useDispatch()
  const usersListData = useSelector((state) =>
    state.users.loading ? [] : state.users.USER_PRACTICES
  )
  const [callRedux, setCallRedux] = useState(true)

  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )


  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })
    // if (_patientId == undefined || _patientId==null || _patientId==0) {
    //   setValue('providerId','' ) 
    // }
  })

  useEffect(() => {
    if (callRedux) dispatch(getUserPractices())
  }, [dispatch, callRedux])

  // useEffect(() => {
  //   if (callRedux) dispatch(getAllProviders())
  // }, [dispatch, callRedux])

  useEffect(() => {
    if (callRedux) dispatch(getFacilities())
  }, [dispatch, callRedux])

  useEffect(() => {
    if (callRedux) dispatch(getProvidersNames())
  }, [dispatch, callRedux])
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,

    borderRadius: 4,
    background: 'white',
    fontSize: '13.6px !important',
    color: 'dark !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 2,
      // borderRadius: 1,
    },
  }
  const openinvitepatient = (event, id) => {
    console.log('Clicked UserEmail: ', id)
    history.push({
      pathname: `/invitePatient`,
      state: { userId: id },
    })
  }
  const keyDown = (e) => {
    if (e.key === 'Enter') {
      getdata(e)
    }
  }
  const [rows, setRows] = useState(null)
  // console.log('pid:',rows.providerId)
  const [age, setAge] = React.useState('BHI')
  const [open, setOpen] = React.useState(false)
  const rowsPerPageOptions = [5,10, 25, 100];

  const handleChange = (event) => {
    setAge(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  
   const [careTeam_id, setCareTeam_id] = useState('')

  const handlecareteam_id = (event) => {
    setCareTeam_id(event.value)
  }
  const [program, setProgram] = useState('')

  const handleProgramChange = (event) => {
    setProgram(event.value)
    setRows(null)
    const postData = {
      program: event.value,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        postData,
        { headers }
      )
      .then((response) => {    if (response.data.length==0) {
        setNoRowsLabel('No Record Found')
      } 
        
      else {
        setRows(response.data)
      }}

    )
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const [status, setStatus] = useState({ value: 'Active', label: 'Active' },
  );

  // Handle status change


  // Ensure the state is set properly on component mount
  // useEffect(() => {
  //   setStatus({ value: 'Active', label: 'Active' });
  // }, []);
  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption)
    console.log('setStatus',selectedOption)
    setRows(null)
    const postData = {
      status: selectedOption.value,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        postData,
        { headers }
      )
      .then((response) => {    if (response.data.length==0) {
        setNoRowsLabel('No Record Found')
      } 
        
      else {
        setRows(response.data)
      }}

    )
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const [isCollapse, setIsCollapse] = useState(true)

  const [flastName, setFlastName] = useState('')
  const [ffirstName, setFfirstName] = useState('')
  const [fdob, setFdob] = useState('')
  const [femail, setFemail] = useState('')
  const [CareTeamList, setCareTeamList] = useState([])
  const [CareTeam, setCareTeam] = useState([])
  const [fssn, setFssn] = useState('')
  const [fphone, setFphone] = useState('')
  const [provider1, setProviderid1] = useState('')
console.log(provider1,'provider1,,')
  
  const [smoking, setSmoking] = useState('')
  const handlesmokingChange = (event) => {
    setSmoking(event.value)
  }
  const allProvidersRedx = useSelector(
    (state) => (state.allProviders.loading ? [] : state.allProviders.NAMES),
    shallowEqual
  )
  const [allProviders, setAllProviders] = useState([])
  useEffect(() => {
    // POST request using axios inside useEffect React hook

    var allProvidersRedux = []
    // console.log('reduxxx', providersRedx)
    allProvidersRedx.forEach((field) => {
      allProvidersRedux.push({
        label: field.lastName + ', ' + field.firstName,
        value: field.id,
      })
    })

    setAllProviders(allProvidersRedux)
    //     if (dtt_userType != 'Admin')
    //     {
    //       setValue('providerId', providersRedux[0].value)
    //     }
    // console.log('provideredux',providersRedux[0].value)

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  const handlecareteam = (event) => {
    setCareTeam(event.value)
  }
  const [fphq9, setFphq9] = useState(0)
  const [fgad7, setFgad7] = useState(0)
  const [flastVisited, setFlastVisited] = useState(0)
  const [refreshAssessment, setrefreshAssessment] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const handlePHQ9Change = (event) => {
    setFphq9(event.target.value)
  }
  const handleGAD7Change = (event) => {
    setFgad7(event.target.value)
  }
  const handleLastVisitedChange = (event) => {
    setFlastVisited(event.target.value)
  }
  const onFilterClear = (event) => {
    setFlastName('')
    setFfirstName('')
    setFdob('')
    setFemail('')
    setFssn('')
    setFphone('')
    setSmoking('')
    setCareTeam('')
    setCareTeam_id('')

    // setrefreshAssessment(!refreshAssessment)
  }

  const onFilterSearch = (event) => {
    setrefreshAssessment(!refreshAssessment)
    //         if (`${localStorage.getItem('dtt_userType')}` != 'Admin')
    // setReadOnly(true)
  }
  useEffect(() => {
    // setrefreshAssessment(!refreshAssessment)
    getdata()
  }, [refreshAssessment])
  // useEffect(() => {
  //   getdata()
  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])

  const getdata = (event) => {
    setRows(null)
    setSubmitting(true)
    setPatientIdArr([])
    const postData = {
      lastName: flastName,
      firstName: ffirstName,
      ssn: fssn,
      providerId:careTeam_id,
      cellPhone: fphone,
      emailAddress: femail,
      program: program,
      status: status.value?status.value:"active",
      careTeam_id: careTeam_id,
   

      dob: fdob,
      smokingStatus: smoking,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        postData,
        { headers }
      )
      .then((response) => {if (response.data.length==0) {
        setNoRowsLabel('No Record Found')
      } 
      else   {
          setRows(response.data)
        }
        setSubmitting(false)
      })
      .catch((error) => {
        setSubmitting(true)
        console.error('There was an error!', error)
      })
  }
  const [isShown, setIsShown] = useState(false)
  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current)

    // 👇️ or simply set it to true
    // setIsShown(true);
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
  })

  //RingCentral works here 

  // const handleopenVideo = () => {
  //   setOpenn(true);
  //   setRequestId(Date.now().toString());
  //     setTimeout(() => {
  //       registerFunction();
  //     }, 5000);


  // };


  const handleCall = () => {
    setOpenn(true);
    setRequestId(Date.now().toString());
    setTimeout(() => {
      callFuntion()
    }, 5000);

  }

  const handleCloseCall = () => {
    setOpenn(false);
  };

  //Video Link
  // useEffect(() => {
  //   if (link) {
  //     window.open(link, '_blank');
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}/Patients/updateRoomofSpecificPatient?patientId=${_patientId}&videoLink=${link}`,
  //         null,
  //         {
  //           headers,
  //         }
  //       )
  //       .then((response) => {
  //         if (response.data.id) {
  //           console.log("SpecificPatient", response.data)
  //         } else {
  //           setApiError(response.data)
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       })
  //   }
  // }, [link]);


  useEffect(() => {
    console.log("check openn", openn && requestId);
    if (openn && !requestId) {
      registerFunction();
    }
  }, [openn]);


  //phone call 
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_URL}/Patients/getPatientInfo?patientid=${_patientId !== undefined ? _patientId : 0
  //       }`,
  //       {
  //         headers,
  //         cache: 'no-store',
  //       }
  //     )

  //     .then((response) => {
  //       console.log("Fetched phoneNumber:", response.data);
  //       if (response.data.phoneNumber) {
  //         setPhoneNumber(() => response.data.phoneNumber);
  //       } else {
  //         console.log("phonenumber nhee aya")
  //         setPhoneNumber('')
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('There was an error!', error)
  //     })
  // }, [_patientId])



  useEffect(() => {
    console.log("check openn", openn && requestId);
    if (openn && !requestId) {
      callFuntion();
    }
  }, [openn]);



  // const registerFunction = () => {
  //   const meetingBody = {
  //     topic: "Embbnux Ji's Meeting",
  //     meetingType: "Scheduled",
  //     password: "",
  //     schedule: {
  //       startTime: 1583312400368,
  //       durationInMinutes: 60,
  //       timeZone: {
  //         id: "1"
  //       }
  //     },
  //     allowJoinBeforeHost: false,
  //     startHostVideo: false,
  //     startParticipantsVideo: false,
  //     audioOptions: [
  //       "Phone",
  //       "ComputerAudio"
  //     ]
  //   };
  //   document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
  //     type: 'rc-adapter-message-request',
  //     requestId: requestId,
  //     path: '/schedule-meeting',
  //     body: meetingBody,
  //   }, '*');
  //   window.addEventListener('message', function (e) {
  //     console.log(e.data)
  //     var data = e.data;
  //     console.log("check request id", requestId);
  //     console.log("check type", e.data.type);
  //     if (data && data.type === 'rc-adapter-message-response') {
  //       if (e.data.responseId === requestId) {
  //         console.log("data respinse", data.response);
  //         console.log("location", data.response.meeting.location);
  //         setLink(data.response.meeting.location);
  //       }
  //     }
  //   });

  // }


  const callFuntion = () => {
    document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
      type: 'rc-adapter-new-call',
      toCall: true,
    }, '*');
  };

  const handleMinimize = () => {
    const iframe = document.getElementById('rc-widget-adapter-frame');
    iframe.style.display = 'none';
    setMinimized(true);
  };

  const handleMaximize = () => {
    const iframe = document.getElementById('rc-widget-adapter-frame');
    iframe.style.display = 'block';
    setMinimized(false);
  };

  return (
    <>


      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '60%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        // alignContent="center"
        // justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '12px',
          paddingRight: '40px',
        }}
      >
        <Grid item xs={3} sm={2} xl={1.2}>
          <SuiTypography>Program:</SuiTypography>
        </Grid>
        <Grid item xs={3} sm={2.1} xl={2.1}  >
          <FormControl>
            <SuiSelect
              defaultValue={{ value: '', label: 'All' }}
              onChange={handleProgramChange}
              options={[
                { value: '', label: 'All' },
                { value: 'BHI', label: 'BHI' },
                { value: 'CoCM', label: 'CoCM' },
              ]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.6} sm={0.6} xl={0.6}>
</Grid>
        {/* <Grid item xs={3} sm={2} xl={1}>
          <SuiTypography>Status:</SuiTypography>
        </Grid>
        <Grid item xs={3} sm={2.1} xl={2.1}  >
          <FormControl>
            <SuiSelect
              // defaultValue={{ value: '', label: 'All' }}
              onChange={handleStatusChange}
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
                { value: 'Discharge', label: 'Discharge' },
              ]}
            />
          </FormControl>
        </Grid> */}
        
      <Grid item xs={3} sm={2} xl={1}>
        <SuiTypography>Status:</SuiTypography>
      </Grid>
      <Grid item xs={3} sm={2.1} xl={2.1}>
        <FormControl>
          <SuiSelect
          
            value={status}
            onChange={handleStatusChange}
            
            options={[
              { value: '', label: 'All' },
              { value: 'Active', label: 'Active' },
              { value: 'Inactive', label: 'Inactive' },
              { value: 'Discharge', label: 'Discharge' },
            ]}
          />

        </FormControl>
      </Grid>
        <Grid item xs={5} sm={2} xl={3}>
          <Button disabled={patientIdArr.length > 0 ? false : true}
            sx={{ mb: 5 }}
            onClick={handleOpenBulkModal}
            variant="gradient"
            color="info"
            size="small"
            style={{
              width: '170px',
              float: 'right',
              height: '33px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <AddOutlinedIcon
              fontSize="medium"
              style={{
                color: '#1a82ad',
                paddingRight: '4px',
                paddingBottom: '2px',
              }}
            ></AddOutlinedIcon>
            Bulk Update
          </Button>
        </Grid>
        <Grid item xs={1} sm={1} xl={1}>
          <Button
            sx={{ mb: 5 }}
            onClick={openNewPatientRegistration}
            variant="gradient"
            color="info"
            size="small"
            style={{
              width: '70px',
              float: 'right',
              height: '33px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
          >
            <AddOutlinedIcon
              fontSize="medium"
              style={{
                color: '#1a82ad',
                paddingRight: '4px',
                paddingBottom: '2px',
              }}
            ></AddOutlinedIcon>
            New
          </Button>
        </Grid>
        <Grid item xs={1} sm={1} xl={1} style={{marginTop: "5px"}}>
          <div>

            <div>
                <CallIcon
                  onClick={handleCall}
                  style={{
                    marginBottom: '10px',
                    float: 'left',
                    marginLeft: '20px',
                    width: '20px',
                    height: '20px',
                    color: 'black',
                    fontSize: '8px',
                    cursor: 'pointer'
                  }}
                />

              {/* <CallIcon onClick={handleCall} style={{
              marginBottom: '10px',
              marginLeft: '20px',
              marginRight: '30px',
              width: '40px',
              height: '25px',
              color: 'black',
              fontSize: '8px',
              cursor: 'pointer',
            }}></CallIcon> */}
            </div>




            <Box>

              {openn ? (
                <Grid
                  container
                  columnSpacing={1}
                  alignContent="center"
                  justifyContent="center"
                
                >
                  <div
                    style={{
                      position: 'absolute',
                      left: '45%',
                      top: '45%',
                      height: '400px',
                      width: '30%',
                      zIndex: 1000
                    }}
                  >
                    <Grid
          container
          columnSpacing={1}
          alignContent="center"
          justifyContent="center"
          position="relative"
          style={{backgroundColor: '#ffffc3', width: '28%', marginLeft: '60%',height: '23px',marginTop: '-3px'}}
        
        >
                    <div>
                      {minimized ? (
                        <KeyboardArrowDownIcon onClick={handleMaximize}
                          style={{
                            marginTop: '8px',
                            marginLeft: '70%',
                            width: '25px',
                            height: '20px',
                            color: 'black',
                            fontSize: '16px',
                            cursor: 'pointer',
                            zIndex: '99999999'
                          }}
                        >Minimize</KeyboardArrowDownIcon>
                      ) : (
                        <MinimizeIcon onClick={handleMinimize}
                          style={{marginTop:'-1rem',
                            marginLeft: '50%',
                            width: '40px',
                            height: '25px',
                            color: 'black',
                            fontSize: '8px',
                            cursor: 'pointer',
                            zIndex: '99999999'
                          }}
                        >Maximize</MinimizeIcon>
                      )}
                    </div>
                    <HighlightOffOutlinedIcon onClick={handleCloseCall}
                  style={{
                    marginTop: '8px',
                    float: 'left',
                    marginLeft: '10px',
                    width: '40px',
                    height: '20px',
                    color: 'black',
                    fontSize: '8px',
                    cursor: 'pointer'
                  }}
                >Close</HighlightOffOutlinedIcon>
                    </Grid>
                    <iframe
                      id="rc-widget-adapter-frame"
                      name="ifr"
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        transform: 'scale(0.9)',
                        transformOrigin: 'top left',
                      }}
                      src={`${doc}`}
                      frameBorder="0"
                      allow="microphone; camera"
                      allowFullScreen
                    />
                  </div>



                </Grid>
              ) : ""}
            </Box>


          </div>
        </Grid>


        <Modal
          open={OpenBulkModal}
          onClose={handleCloseBulkModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <Grid container columnSpacing={1}>

              <Grid item xs={6}>
                <Typography
                  component="label"
                  color="black"
                  fontWeight={"bold"}
                  fontSize="18px"
                >
                  Update Patient
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CancelOutlined
                  onClick={handleCloseBulkModal}
                  fontSize="large"
                  color="red"
                  style={{ color: "red", paddingRight: "5px", float: "right" }}
                ></CancelOutlined>
              </Grid>


              <Grid item xs={6} style={{ fontWeight: 500 }}>
                <Typography component="label" color="black" fontSize="15px">
                  Provider
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ fontWeight: 500 }}>
                <Typography component="label" color="black" fontSize="15px">
                  Status
                </Typography>
              </Grid>


              <Grid item xs={6} style={{ width: '300px' }}>
                <Controller
                  name="providerId"
                  {...register('providerId')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      // isClearable
                      {...field}
                      default
                      value={providersRedux.filter(function (option) {
                        return option.value === getValues('providerId')
                      })}
                      onChange={(value) => {
                        setValue('providerId', value.value)
                      }}
                      options={providersRedux}
                      error={errors.providerId}
                    />
                  )}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>
                  {errors.providerId?.message}
                </p>
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="status"
                  {...register("status")}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      // isClearable
                      {...field}
                      options={[
                        { value: "Active", label: "Active" },
                        { value: "InActive", label: "InActive" },
                        { value: "Discharge", label: "Discharge" },
                      ]}
                      value={{ label: getValues("status") }}
                      onChange={(value) => setValue("status", value)}
                    />
                  )}
                />
              </Grid>


              <Grid item xs={6} style={{ width: '300px' }}>
                <SuiTypography component="label" variant="caption" fontSize="15px">
                  Program
                </SuiTypography>
              </Grid>
              <Grid item xs={6} style={{ width: '300px' }}>
                <SuiTypography component="label" variant="caption" fontSize="15px">
                  Smoking
                </SuiTypography>
              </Grid>

              <Grid item xs={6} style={{ width: '300px' }}>
                <Controller
                  name="program"
                  {...register('program')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      {...field}
                      value={{ label: getValues('program') }}
                      onChange={(value) => setValue('program', value.label)}
                      options={[
                        { value: '', label: '' },
                        { value: 'BHI', label: 'BHI' },
                        { value: 'CoCM', label: 'CoCM' },
                      ]}
                      error={errors.program}
                    />

                  )}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>
                  {errors.program?.message}
                </p>
              </Grid>
              <Grid item xs={6} style={{ width: '300px' }}>
                <Controller
                  name="smokingStatus"
                  {...register('smokingStatus')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      {...field}
                      value={{ label: getValues('smokingStatus') }}
                      onChange={(value) => setValue('smokingStatus', value.label)}
                      options={[
                        { value: '', label: '' },
                        { value: 'smokingCessation', label: 'Smoking Cessation' },
                      ]}
                    />
                  )}
                />
              </Grid>






              <Grid item xs={12}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="gradient"
                  disabled={submitting}
                  style={{
                    marginTop: "30px",
                    marginBottom: "10px",
                    float: "right",
                    marginLeft: "auto",
                    width: "80px",
                    height: "35px",
                    backgroundColor: "#FCBE13",
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  <SaveOutlined
                    fontSize="medium"
                    style={{
                      color: "#1a82ad",
                      paddingRight: "5px",
                      paddingBottom: "2px",
                    }}
                  ></SaveOutlined>
                  Save
                </Button>
              </Grid>



            </Grid>
          </Box>
        </Modal>

      </Grid>
      <SuiBox mb={2}>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            width: '100%',
            marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            marginLeft: '1px',
            padding: '15px',
            // paddingLeft: '15px',
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={6} sm={3} xl={3}>
            <Typography style={{ fontSize: '14px' }}>Last Name</Typography>
          </Grid>
          <Grid item xs={6} sm={3} xl={3}>
            <Typography style={{ fontSize: '14px' }}>First Name</Typography>
          </Grid>
          <Grid item xs={6} sm={3} xl={3}>
            <Typography style={{ fontSize: '14px' }}>DOB</Typography>
          </Grid>
          <Grid item xs={6} sm={3} xl={3}>
            <Typography style={{ fontSize: '14px' }}>SSN</Typography>
          </Grid>
          <Grid item xs={6} sm={3} xl={3}>
            <SuiInput
              onChange={(e) => setFlastName(e.target.value)}
              value={flastName}
              placeholder="Please enter last name"
              name="lastName"
              onKeyDown={keyDown}
              label="LastName"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={6} sm={3} xl={3}>
            <SuiInput
              onChange={(e) => setFfirstName(e.target.value)}
              value={ffirstName}
              placeholder="Please enter first name"
              onKeyDown={keyDown}
              name="firstName"
              label="FirstName"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={6} sm={3} xl={3}>
            <SuiInput
              onChange={(e) => setFdob(e.target.value)}
              value={fdob}
              type="date"
              placeholder="Please enter dob"
              name="dob"
              onKeyDown={keyDown}
              label="dob"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={6} sm={3} xl={2}>
            <InputMask
              mask="999-99-9999"
              disabled={false}
              value={fssn}
              onKeyDown={keyDown}
              style={{ height: '30px' }}
              onChange={(e) => {
                // setValue('ssn', e.target.value)
                setFssn(e.target.value)
              }}
            >
              {() => (
                <SuiInput
                  id="outlined-basic"
                  fullWidth
                  margin="dense"
                  placeholder="Enter SSN"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={6} sm={3} xl={1}>
            <Button
              sx={{ mb: 2 }}
              onClick={handleClick}
              variant="gradient"
              size="small"
              style={{
                width: '50px',
                float: 'left',
                height: '33px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            // color="warning"
            // size="small"

            // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              {isShown ? (
                <ExpandLessIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandMoreIcon>
              )}
            </Button>
          </Grid>
          {/* <Grid item xs={6} sm={3} xl={3} style={{marginTop:'-0.5rem'}}>
            <Grid container xs={12}>
            <Typography style={{ fontSize: '14px' }}>Provider</Typography>
          </Grid>
          <Grid container xs={12}>
          <Controller
                  name="providerId"
                  {...register('providerId')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      // isClearable
                      {...field}
                      default
                      value={providersRedux.filter(function (option) {
                        return option.value === getValues('providerId')
                      })}
                      onChange={(value) => {
                        setValue('providerId', value.value);
                        setProviderid1(value.value);
                      }}
                      
                      options={providersRedux}
                      error={errors.providerId}
                    />
                  )}
                />          </Grid></Grid> */}
          {isShown && (
            <>
              <Grid
                container
                spacing={1}
                style={{
                  marginLeft: '2px',
                }}
              >
                <Grid item xs={6} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px' ,marginTop:'0.5rem'}}>
                    Email Address
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} xl={3} style={{ fontSize: '14px' ,marginTop:'0.5rem'}}>
                  <Typography style={{ fontSize: '14px' }}>Phone No</Typography>
                </Grid>
                <Grid item xs={6} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px' ,marginTop:'0.5rem'}}>
                    Smoking Status
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px' ,marginTop:'0.5rem'}}>Provider</Typography>
                </Grid>
                <Grid item xs={6} sm={3} xl={3}>
                  <SuiInput
                    onChange={(e) => setFemail(e.target.value)}
                    value={femail}
                    placeholder="Please enter email address"
                    name="emailAddress"
                    onKeyDown={keyDown}
                    label="emailAddress"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6} sm={3} xl={3}>
                  <InputMask
                    mask="+1 999-99-9999"
                    disabled={false}
                    value={fphone}
                    onKeyDown={keyDown}
                    style={{ height: '30px' }}
                    onChange={(e) => {
                      // setValue('ssn', e.target.value)
                      setFphone(e.target.value)
                    }}
                  >
                    {() => (
                      <SuiInput
                        id="outlined-basic"
                        fullWidth
                        margin="dense"
                        placeholder="Enter Phone NO"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={6} sm={3} xl={3}>
                  <SuiSelect
                    defaultValue={{ value: '', label: 'All' }}
                    onChange={handlesmokingChange}
                    options={[
                      { value: '', label: 'All' },
                      {
                        value: 'smoking Cessation',
                        label: 'Smoking Cessation',
                      },
                    ]}
                    onKeyDown={keyDown}
                  />
                </Grid>
                <Grid item xs={6} sm={3} xl={3} lg={3}>
            <SuiSelect
              // isClearable
              // {...field}
              // error={errors.providerId}
              // default
              value={allProviders.filter(function (option) {
                return option.value === careTeam_id
              })}
              // onChange={(value) => {
              //   setValue('providerId', value.value)
              // }}

              onChange={handlecareteam_id}
              options={allProviders}
              onKeyDown={keyDown}
            />
            {/* <InputMask
            mask="999-99-9999"
            disabled={false}
            value={fssn}
            onKeyDown={keyDown}
            style={{ height: '30px' }}
            onChange={(e) => {
              // setValue('ssn', e.target.value)
              setFssn(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter SSN"
              />
            )}
          </InputMask> */}
          </Grid>
              
                {/* <Grid item xs={6} sm={3} xl={0}>

                 </Grid> */}
              </Grid>
            </>
          )}

          <Grid container>
            <Grid item xs={9} sm={9} xl={9}></Grid>
            <Grid item xs={3} sm={3} xl={3}>
              <Button
                onClick={onFilterSearch}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  float: 'right',
                  marginLeft: '10px',
                  width: '85px',
                  height: '35px',
                  backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                <SearchOutlinedIcon
                  fontSize="medium"
                  style={{ color: '#1a82ad', paddingBottom: '1px' }}
                ></SearchOutlinedIcon>
                Search
              </Button>
              <Button
                onClick={onFilterClear}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  marginLeft: 'auto',
                  float: 'right',
                  width: '80px',
                  height: '35px',
                  backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                <CleaningServicesOutlinedIcon
                  fontSize="medium"
                  style={{ color: '#0a9ff0', paddingRight: '5px' }}
                ></CleaningServicesOutlinedIcon>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mb={2}>
        <div style={{ height: 353.5, width: '100%' }}>
          {rows === null || rows.length === 0 ? (
            <Typography>{NoRowsLabel || GETTING_ROWS_LABEL}</Typography>

          ) : (
            <DataGrid
  rows={rows}
  columns={columns}
  // pageSize={5}
  rowsPerPageOptions={rowsPerPageOptions}
  // rowsPerPageOptions={[5]}
  sx={{
    '& .MuiTablePagination-root': {
      '& .MuiTablePagination-input': {
        width: '120px !important', 
        marginTop:'-13px'
      },
      '& .MuiTablePagination-select': {
        minWidth: '70px !important', paddingTop:'-2px',marginTop:'-8px'
      },
    },
    ...gridRowStyle,
  }}
  // pageSizeOptions={[5,10,15]} 
  // sx={gridRowStyle}
  checkboxSelection
  disableSelectionOnClick
  onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
/>

          )}
        </div>
      </SuiBox>
    </>
  )
}

export default PatientSearchNew
