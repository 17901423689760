
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {
  Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import SuiTypography from 'components/SuiTypography'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Grid } from '@mui/material'
import Switch from '@mui/material/Switch'

function MedicalHistory(props) {

  const pastMedicalHistory = ['Diabetes', 'Hypertension', 'Thyroid Disease', 'Heart Disease', 'Kidney Disease','Lung Disease','Autoimmune Disease','Chronic Pain ','Arthritis','Visual Impairment','Dyslipidemia','Sleep Apnea','Seasonal Allergies','Insomnia','Gastrointestinal Problems']

  return (
    <>

    
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Allergies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Medications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          </Typography>
        </AccordionDetails>
      </Accordion> 
     
     
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Past Psychiatrist History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Past Medical History</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container xs={12} sm={12} md={12} lg={12}>
          {pastMedicalHistory.map((e,i)=>{
            return(
              <>
                <Grid container xs={12} sm={4} md={4} lg={4}>               
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                   
                    <Grid item md={10}>
                      <SuiTypography>
                        {e}
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      md={2}
                    >
                      <>
                        <Switch
                          // checked={item.isChecked}
                          color="warning"
                          onChange={(event) => {}}
                        />
                      </>
                    </Grid>                
                  </Grid>
                </Grid>
                </>
              
            )


          })}
                
                </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Past Surgical History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Socail History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Family History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
   
    </>
  
  )
}

export default MedicalHistory
