import React, { useEffect, useState } from 'react'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import CallIcon from '@mui/icons-material/Call';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, Link } from 'react-router-dom';
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import axios from 'axios'
import { WindowsControls } from 'react-windows-controls';

const CallComponent = (props) => {
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const [phoneNumber, setPhoneNumber] = useState("");
  const [link, setLink] = useState("");
  const [requestId, setRequestId] = useState("");
  const [doc, setDoc] = useState(
    'https://ringcentral.github.io/ringcentral-embeddable/app.html'
  )
  // const requestId = "";
  const [callLink, setCallLink] = useState('');
  const [minimized, setMinimized] = useState(false);
  const [openn, setOpenn] = useState(false);
  const location = useLocation()
  const _patientId = props.patientId ||localStorage.getItem('patientId')
  console.log("patientId checked", _patientId);
  
  const handleopen = () => {
    setOpenn(true);
    setRequestId(Date.now().toString());
      setTimeout(() => {
        registerFunction();
      }, 5000);


  };


  const handleCall = () => {
    setOpenn(true);
    setRequestId(Date.now().toString());
      setTimeout(() => {
      callFuntion()
      }, 5000);

  }

  const handleClose = () => {
    setOpenn(false);
  };


  useEffect(() => {
    if (link) {
      window.open(link, '_blank');
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Patients/updateRoomofSpecificPatient?patientId=${_patientId}&videoLink=${link}`,
          null,
          {
            headers,
          }
        )
        .then((response) => {
          if (response.data.id) {
            console.log("SpecificPatient", response.data)
          } else {
            setApiError(response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [link]);


  useEffect(() => {
    console.log("check openn", openn && requestId);
    if (openn && !requestId) {
      registerFunction();
    }
  }, [openn]);


  //phone call 
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/getPatientInfo?patientid=${_patientId !== undefined ? _patientId : 0
        }`,
        {
          headers,
          cache: 'no-store',
        }
      )

      .then((response) => {
       
        if (response.data.phoneNumber) {
          setPhoneNumber(() => response.data.phoneNumber);
        } else {
          console.log("phonenumber nhee aya")
          setPhoneNumber('')
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [_patientId])



  useEffect(() => {
    console.log("check openn", openn && requestId);
    if (openn && !requestId) {
      callFuntion();
    }
  }, [openn]);



  const registerFunction = () => {
    const meetingBody = {
      topic: "Embbnux Ji's Meeting",
      meetingType: "Scheduled",
      password: "",
      schedule: {
        startTime: 1583312400368,
        durationInMinutes: 60,
        timeZone: {
          id: "1"
        }
      },
      allowJoinBeforeHost: false,
      startHostVideo: false,
      startParticipantsVideo: false,
      audioOptions: [
        "Phone",
        "ComputerAudio"
      ]
    };
    document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
      type: 'rc-adapter-message-request',
      requestId: requestId,
      path: '/schedule-meeting',
      body: meetingBody,
    }, '*');
    window.addEventListener('message', function (e) {
      console.log(e.data)
      var data = e.data;
      console.log("check request id", requestId);
      console.log("check type", e.data.type);
      if (data && data.type === 'rc-adapter-message-response') {
        if (e.data.responseId === requestId) {
          console.log("data respinse", data.response);
          console.log("location", data.response.meeting.location);
          setLink(data.response.meeting.location);
        }
      }
    });

  }


  const callFuntion = () => {
    console.log("phoneNumber Get", phoneNumber);
    const PhoneNo = phoneNumber;
    document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
      type: 'rc-adapter-new-call',
      to:  PhoneNo,
      toCall: true,
    }, '*');
  };

  const handleMinimize = () => {
    const iframe = document.getElementById('rc-widget-adapter-frame');
    iframe.style.display = 'none';
    setMinimized(true);
  };

  const handleMaximize = () => {
    const iframe = document.getElementById('rc-widget-adapter-frame');
    iframe.style.display = 'block';
    setMinimized(false);
  };
  

  return (
    <div>
    <div>
   
        <VideoCallIcon
          onClick={handleopen}
          style={{
            marginBottom: '10px',
            float: 'left',
            marginLeft: '20px',
            width: '40px',
            height: '25px',
            color: 'black',
            fontSize: '8px',
            cursor: 'pointer'
          }}
        />
     
  
      <CallIcon onClick={handleCall} style={{
        marginBottom: '10px',
        marginLeft: '20px',
        marginRight: '30px',
        width: '40px',
        height: '25px',
        color: 'black',
        fontSize: '8px',
        cursor: 'pointer',
        backgroundColor: '#ffffc3'
      }}></CallIcon>
  
 
    </div>
    <Box>
  {openn ? (
    <Grid
      container
      columnSpacing={1}
      alignContent="center"
      justifyContent="center"
    >
      <div
        style={{
          marginTop: '94px',
          position: 'absolute',
          left: '62%',
          height: minimized ? '23px' : '430px', // Adjust height when minimized
          width: minimized ? '30px' : '30%', // Adjust width when minimized
          zIndex: 1000
        }}
      >
        <Grid
          container
          columnSpacing={1}
          alignContent="center"
          justifyContent="center"
          position="relative"
          style={{backgroundColor: minimized ? 'transparent' : '#ffffc3', width: '30%', marginLeft: minimized ? '60%' : '0', height: '23px', marginTop: '10px'}}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {minimized ?  (
              <KeyboardArrowDownIcon onClick={handleMaximize}
                style={{
                  marginLeft: '60%',
                  marginBottom: '-5px',
                  width: '40px',
                  height: '25px',
                  color: 'black',
                  fontSize: '8px',
                  cursor: 'pointer',
                  zIndex: '99999999'
                }}
              >Minimize</KeyboardArrowDownIcon>
            ):(
              <MinimizeIcon onClick={handleMinimize}
                style={{marginTop:'-1rem',
                  marginLeft: '-15%',
                  width: '40px',
                  height: '25px',
                  color: 'black',
                  fontSize: '8px',
                  cursor: 'pointer',
                  zIndex: '99999999'
                }}
              >Maximize</MinimizeIcon>
            )}
            <HighlightOffOutlinedIcon onClick={handleClose}
              style={{
                marginTop: '7px',
                float: 'left',
                marginLeft: '1px',
                width: '20px',
                height: '20px',
                color: 'black',
                fontSize: '8px',
                cursor: 'pointer'
              }}
            >Close</HighlightOffOutlinedIcon>
          </div>
        </Grid>
        <iframe
          id="rc-widget-adapter-frame"
          name="ifr"
          style={{
            position: 'absolute',
            height: minimized ? '0' : '100%', 
            width: minimized ? '0' : '100%', 
            transform: 'scale(0.9)',
            transformOrigin: 'top left',
          }}
          src={`${doc}`}
          frameBorder="0"
          allow="microphone; camera"
          allowFullScreen
        />
      </div>
    </Grid>
  ) : ""}
</Box>


  
  </div>
  )
}

export default CallComponent;