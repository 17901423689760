/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import axios from 'axios'
import AddIcon from 'assets/add.png'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
import Table from 'examples/Tables/Table'
import { useEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { DataGrid } from '@mui/x-data-grid'
import { Grid, Snackbar, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import customStyle from '../assets/customStyle.css'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from "@mui/material/CircularProgress";

const history = createBrowserHistory({ forceRefresh: true })

function Users() {
  const gridStyle = {overflow:'hidden',
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const [refresh, setRefresh] = useState(false)

  const gridRowStyle = {overflow:'hidden',
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
       borderRadius: 1.8,
    },
  }
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = (cell) => {
    // Your delete logic here...
    const postData = {
      lastName: cell.row.lastName,
      nPI: cell.row.nPI,
      phoneNo: cell.row.phoneNo,
      zipCode: cell.row.zipCode,
      id: cell.row.id,
      inactive: true,
      firstName: cell.row.firstName,
      practiceId: cell.row.practiceId,
      dOB:cell.row.dOB,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Providers/addProvider`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
        // setOpenNote(false);
        // console.log('response.data: ', response.data);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    // Close the dialog
    setOpenDialog(false);
  };
  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => (
        <SuiButton
          ize="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            openUserRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.firstName}`}</SuiButton>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'title',
      headerName: 'Title',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.7,
    },
    {
      field: 'nPI',
      headerName: 'NPI',
      cellClassName: 'gridCell',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'phoneNo',
      headerName: 'PhoneNo',
      cellClassName: 'gridCell',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'zipCode',
      headerName: 'Zip Code',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    // {
    //   field: 'ActivePatients',
    //   headerName: 'Active Patients',
    //   cellClassName: 'gridCell',
    //   sortable: false,
     
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    // },
    // {
    //   field: 'InActivePatients',
    //   headerName: 'InActive Patients',
    //   cellClassName: 'gridCell',
    //   sortable: false,
  
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    // },
    {
      field: 'delete',
      headerName: '',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      minWidth: 50,
      renderCell: (cell) => (
        <>
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
          }}
          color="red"
          onClick={() => setOpenDialog(true)}
        />
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            invisible: true,
          }}
          PaperProps={{
            style: {background:'#cef5f0',
            boxShadow:'none',
            borderRadius:'8px'
            },
         }}
        >
        <div style={{borderRadius:'8px',width:'22rem',}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undone.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={()=>{handleDelete(cell)}} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button   onClick={() => setOpenDialog(false)}   style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
        </Dialog>
      </>
      ),
    },
  ]

  const [rows, setRows] = useState(null)
  const [submitting, setsubmitting] = useState(false)

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  useEffect(() => {setsubmitting(true)
    // POST request using axios inside useEffect React hook
    axios

      .get(`${process.env.REACT_APP_API_URL}/Providers/getProviders`, {
        headers,
      })
      .then((response) =>{ setRows(response.data)
        setsubmitting(false)
      })
      .catch((error) => {setsubmitting(false)
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  const openUserRegistration = (event, id) => {
    console.log('Clicked UserEmail: ', id)
    history.push({
      pathname: `/providerRegistration`,
      state: { userId: id },
    })
  }
  const openinvitepatient = (event, id) => {
    console.log('Clicked UserEmail: ', id)
    history.push({
      pathname: `/invitePatient`,
      state: { userId: id },
    })
  }
  console.log('rows: ', rows)
  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '50px',
        }}
      >
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
            <Button
              sx={{ mb: 5}}
              onClick={openUserRegistration}
              variant="gradient"
            color="info"
            size="small"
            style={{
              width: '80px',
              height:"33px",
              backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px',

          }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

        >
          <AddOutlinedIcon
          fontSize='medium'
          style={{ color:'#1a82ad', paddingRight:'4px', paddingBottom:'2px'}}
          >
          </AddOutlinedIcon>
            New
            </Button>
          </SuiBox>
        </Grid>
        <Grid item xs={12} style={{ width: '300px',overflow:'hidden' }}>
          <SuiBox mb={3}>
            <div style={{ height: 353.5, width: '100%' }}>
            {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ) :rows === null || rows.length === 0 ? "No Record Found" : (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  headerHeight={37}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  sx={gridRowStyle}
                />
              )}
            </div>
          </SuiBox>
        </Grid>
      </Grid>
    </>
    // <SuiBox py={3}>
    //   <SuiButton
    //     sx={{ mb: 5, width: 200 }}
    //     onClick={openUserRegistration}
    //     variant="gradient"
    //     color="info"
    //   >
    //     Add New
    //   </SuiButton>
    //   <SuiBox mb={3}>
    //     <div style={{ height: 400, width: "100%" }}>
    //       {rows === null || rows.length === 0 ? null : (
    //         <DataGrid
    //           rows={rows}
    //           columns={columns}
    //           pageSize={5}
    //           rowsPerPageOptions={[5]}
    //           sx={gridRowStyle}
    //         />
    //       )}
    //     </div>
    //   </SuiBox>
    // </SuiBox>
  )
}

export default Users
