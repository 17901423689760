import { useState, useEffect } from 'react'
// import ProviderSchedulerItem from '../SchedulerComponents/ProviderSchedulerItem'
import ProviderSchedulerItem from 'Modules/ProviderSchedulerItem';


// ** MUI Imports
import Box from '@mui/material/Box'
import React from 'react'
import { Grid, Typography } from '@mui/material'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'



const Provider_scheduler = () => {
  // const [isLoading, setLoading] = useState(false);
  const [submitting, setsubmitting] = useState(false)
  const DAY_OF_WEEK=[{'day':"Monday", "startTime":"" , "endTime":"","facilityCheck":false},
  {'day':"Tuesday", "startTime":"" , "endTime":"","facilityCheck":false},
  {'day':"Wednesday", "startTime":"" , "endTime":"","facilityCheck":false},
  {'day':"Thursday", "startTime":"" , "endTime":"","facilityCheck":false},
  {'day':"Friday", "startTime":"" , "endTime":"","facilityCheck":false},
  {'day':"Saturday", "startTime":"" , "endTime":"","facilityCheck":false},
  {'day':"Sunday", "startTime":"" , "endTime":"","facilityCheck":false}]

  const token = localStorage.getItem('medAssistToken')
  //  const history = createBrowserHistory({ forceRefresh: true })
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };

  const [refresh, setRefresh] = useState(false)
  const [providerRedux, setprovidersRedux] = useState([])
  const [reduxLoaded, setreduxLoaded] = useState(false)
  const providersRedx = useSelector(state => state.providers.NAMES, shallowEqual)




  const [providerId, setproviderId] = useState('')
  const [getData, setGetdata] = useState('')
console.log(getData,',,,,')
  console.log("-=---------------------: " , providerId)

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize : '12px',
      border: '1px solid #ccc',
      boxShadow: state.isFocused ? '0 0 0 2px #007bff' : null,
      // '&:hover': { borderColor: 'red' },
    }),
     option: (provided, state) => ({
      ...provided,
      fontSize : '11px',
      cursor : 'pointer',
      border: '1px solid #ccc',
      // boxShadow: state.isFocused ? '0 0 0 2px #007bff' : null,
      '&:hover': { borderColor: 'red' },
    }),
  };



  useEffect(() => {
    var providerRedux = []
    providersRedx.forEach(field => {
      providerRedux.push({
        label: field.name,
        value: field.id
      })
    })
    setprovidersRedux(providerRedux)
    console.log(providerRedux)
    setreduxLoaded(true)
  }, [])

  const [rows, setRows] = useState(null)
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    background: 'white',
    fontSize: '13.6px !important',
    color: 'black !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important'
    },
    '& .super-app-theme--header': {
      backgroundColor: '#A574FD',
      color: 'white'
    }
  }

  const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string().required('Password is required').min(3, 'Password must be at least 3 characters'),
    // .max(15, 'Password must not exceed 15 characters'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords does not match')
  })
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema)
    // defaultValues: defValues
  })


  return (
    <Box>
      <>
      
          <div>
         
            <Grid
              container
              style={{
                paddingTop: '10px',
                paddingLeft: '50px',
                paddingRight: '50px'
              }}
            >
              <Grid item xs={1} >
                <Typography color='black' variant='caption' fontSize='15px'>
                  Provider
                </Typography>
              </Grid>

              <Grid item xs={3} style={{ width: '300px'  }}>
                <Controller
                  name='providerId'
                  {...register('providerId')}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      // isClearable
                      size='small'
                      {...field}
                      default
                      value={providerRedux.filter(function (option) {
                        return option.value == getValues('providerId')
                      })}
                      onChange={value => {
                        setValue('providerId', value != null ? value.value : '')
                         setproviderId(value.value)
                      }}
                      options={providerRedux}
                      styles={customStyles}
                    />
                  )}
                />
                 {getData?'':<Grid> Please select provider</Grid>}  
              </Grid>

              <Grid item xs={8}></Grid>
            </Grid>

            {/* {isLoading ? <CircularProgress/> : ( */}
            <Grid   style = {{marginTop : "20px"}}> 
            { DAY_OF_WEEK.map((item) =>
              <ProviderSchedulerItem key={item.day} getData={setGetdata} dayOFWeek={item.day}  providerId={providerId} />
            )}
            </Grid>
            {/* )} */}
          </div>
       
      </>
    </Box>
  )
}

export default Provider_scheduler
